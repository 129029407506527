/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeRiskCondition.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeAppRights.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXePatientRule.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ConditionID":{"oneOf":[{"nullable":true,"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeRiskCondition.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"FilterQuery":{"type":"string","maxLength":2147483647},"GeneratedTStamp":{"format":"date-time","nullable":true,"type":"string"},"IsRecurring":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"RightID":{"oneOf":[{"nullable":true,"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppRights.json#"}]},"RuleData":{"type":"string","maxLength":2000},"RuleEndDate":{"format":"date-time","nullable":true,"type":"string"},"RuleGroupID":{"type":"string","maxLength":200},"RuleSetID":{"type":"integer","primaryKey":"generated"},"RuleStartDate":{"format":"date-time","nullable":true,"type":"string"},"Sequence":{"defaultValue":0,"maximum":99,"type":"integer"},"Source":{"type":"string","maxLength":500},"URL":{"type":"string","maxLength":500}},"required":["Active","IsRecurring"],"$id":"com.thrasys.xnet.erp.assets.AssetXePatientRule.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXePatientRule.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
