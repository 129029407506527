/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXgClinicalRefData.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeClinicalRefData.json#"},"Active":{"defaultValue":true,"type":"boolean"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DataFilter":{"type":"string","maxLength":100},"Description":{"type":"string","maxLength":2000},"IsActiveStatus":{"defaultValue":true,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":200},"ParentID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXgClinicalRefData.json#"}]},"RefDataID":{"type":"integer","primaryKey":"generated"},"ScoreMax":{"exclusiveMaximum":10000,"type":"number"},"ScoreMin":{"exclusiveMaximum":10000,"type":"number"},"ScoreValue":{"exclusiveMaximum":10000,"type":"number"},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"required":["Active","IsActiveStatus"],"$id":"com.thrasys.xnet.erp.assets.AssetXeClinicalRefData.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeClinicalRefData.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
