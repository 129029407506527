/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeBillingGroup.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeCatContentType.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeWorkflow.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeServiceCatalog.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"BillingGroupID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeBillingGroup.json#"}]},"CatContentTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeCatContentType.json#"}]},"CatalogID":{"type":"integer","primaryKey":"generated"},"CatalogTypeID":{"type":"string","maxLength":30},"Code":{"type":"string","maxLength":50},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"FilterValue":{"type":"string","maxLength":64},"HierarchyLevel":{"maximum":999,"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":200},"ParentID":{"type":"integer"},"RightID":{"type":"string","maxLength":60},"RootNodeID":{"type":"integer"},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"},"WorkflowID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#"}]}},"required":["Active"],"$id":"com.thrasys.xnet.erp.assets.AssetXeServiceCatalog.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeServiceCatalog.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
