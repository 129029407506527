/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeServiceBase.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeBedClass.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"BedClassID":{"type":"string","primaryKey":"supplied","maxLength":32},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DemandServiceID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceBase.json#"}]},"Description":{"type":"string","maxLength":500},"EnterpriseID":{"type":"string"},"FamilyServiceID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceBase.json#"}]},"IsPartDayCheckout":{"defaultValue":true,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"PartDayFactor":{"defaultValue":0.5,"exclusiveMaximum":100,"type":"number"},"PartDayHourMin":{"defaultValue":0,"exclusiveMaximum":100,"type":"number"},"SecondaryServiceID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceBase.json#"}]},"ServiceID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceBase.json#"}]}},"required":["Active","IsPartDayCheckout"],"$id":"com.thrasys.xnet.erp.assets.AssetXeBedClass.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeBedClass.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
