/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeLabContainerType.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeUnit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeLabContainer.json#"},"Abbreviation":{"type":"string","maxLength":20},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"ContainerID":{"type":"integer","primaryKey":"generated"},"ContainerName":{"type":"string","maxLength":100},"ContainerSize":{"exclusiveMaximum":10000000000,"type":"number"},"ContainerTypeID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeLabContainerType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"EnterpriseID":{"type":"string"},"LabUnitID":{"oneOf":[{"nullable":true,"type":"string","maxLength":64},{"$ref":"com.thrasys.xnet.app.assets.AssetXeUnit.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Yield":{"exclusiveMaximum":10000000000,"type":"number"}},"required":["Abbreviation","Active","ContainerName","ContainerSize"],"$id":"com.thrasys.xnet.erp.assets.AssetXeLabContainer.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeLabContainer.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
