/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeCashSessionStatus.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeOrgUnit.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeUserData.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeCashSession.json#"},"AccountID":{"type":"integer"},"BeginDateTime":{"format":"date-time","nullable":true,"type":"string"},"CashSessionID":{"type":"integer","primaryKey":"generated"},"CashSessionStatusID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeCashSessionStatus.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EndDateTime":{"format":"date-time","nullable":true,"type":"string"},"EnterpriseID":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"RegisterID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeOrgUnit.json#"}]},"Username":{"oneOf":[{"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeUserData.json#"}]}},"$id":"com.thrasys.xnet.erp.assets.AssetXeCashSession.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeCashSession.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
