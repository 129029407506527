/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeAssessment.json';
import schema_1 from './com.thrasys.xnet.assets.AssetXeRuleInfo.json';
import schema_2 from './com.thrasys.xnet.erp.assets.AssetXePatientRuleSet.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeAssessmentGroup.json#"},"Active":{"defaultValue":true,"type":"boolean"},"AssessmentID":{"primaryKey":"supplied","oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeAssessment.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"GroupAssessmentID":{"primaryKey":"supplied","oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeAssessment.json#"}]},"IsRemoveable":{"defaultValue":true,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"ProgramBits":{"defaultValue":1,"type":"integer"},"RuleInfoID":{"oneOf":[{"nullable":true,"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.assets.AssetXeRuleInfo.json#"}]},"RuleSetID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatientRuleSet.json#"}]},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"required":["Active","IsRemoveable","ProgramBits"],"$id":"com.thrasys.xnet.erp.assets.AssetXeAssessmentGroup.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeAssessmentGroup.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
