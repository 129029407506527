/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeProductType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXgProduct.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveIngredientUnit":{"type":"string","maxLength":30},"ActiveNumeratorStength":{"type":"string","maxLength":30},"ApplicationNumber":{"type":"string","maxLength":100},"CategoryName":{"type":"string","maxLength":100},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DeaSchedule":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":1000},"EndDate":{"format":"date","nullable":true,"type":"string"},"FDBID":{"type":"string","maxLength":50},"FormID":{"type":"string","maxLength":50},"LabelerNumber":{"type":"string","maxLength":100},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":500},"NameSuffix":{"type":"string","maxLength":100},"OriginalCode":{"type":"string","maxLength":30},"PharmClass":{"type":"string","maxLength":100},"ProductCode":{"type":"string","maxLength":30},"ProductID":{"type":"integer","primaryKey":"generated"},"ProductTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeProductType.json#"}]},"RouteID":{"type":"string","maxLength":50},"StandardName":{"type":"string","maxLength":200},"StartDate":{"format":"date","nullable":true,"type":"string"},"SubstanceName":{"type":"string","maxLength":100}},"required":["Active","Code"],"$id":"com.thrasys.xnet.erp.assets.AssetXgProduct.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXgProduct.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
