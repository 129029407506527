/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeServiceBookingStatus.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CalendarColor":{"type":"string","maxLength":8},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"IsActiveStatus":{"defaultValue":true,"type":"boolean"},"IsCalendarDisplay":{"defaultValue":true,"type":"boolean"},"IsEndStatus":{"defaultValue":false,"type":"boolean"},"IsSystem":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ParentID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceBookingStatus.json#"}]},"SequenceNo":{"maximum":999,"type":"integer"},"StatusID":{"type":"string","primaryKey":"supplied","maxLength":16}},"required":["Active","IsActiveStatus","IsCalendarDisplay","IsEndStatus","IsSystem","Name"],"$id":"com.thrasys.xnet.erp.assets.AssetXeServiceBookingStatus.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeServiceBookingStatus.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
