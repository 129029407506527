/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeResourceType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXePatientProviderType.json#"},"Active":{"defaultValue":true,"type":"boolean"},"AssignmentType":{"type":"string","maxLength":50},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DefaultResourceTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.app.assets.AssetXeResourceType.json#"}]},"IsTentative":{"defaultValue":false,"type":"boolean"},"IsUnique":{"defaultValue":"n","type":"string","maxLength":1},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ProviderTypeID":{"type":"string","primaryKey":"supplied","maxLength":30},"Sequence":{"maximum":999,"type":"integer"}},"required":["Active","IsTentative","IsUnique"],"$id":"com.thrasys.xnet.erp.assets.AssetXePatientProviderType.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXePatientProviderType.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
