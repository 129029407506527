/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeProductConversion.json#"},"AccountID":{"type":"integer"},"ConversionID":{"type":"integer","primaryKey":"generated"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"MassUnit":{"type":"string","maxLength":64},"MassVolumeFactor":{"exclusiveMaximum":10000000000,"type":"number"},"MasterRecord":{"type":"string","maxLength":5},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"PackFactor":{"exclusiveMaximum":10000000000,"type":"number"},"Status":{"type":"string","maxLength":15},"VolumeUnit":{"type":"string","maxLength":64}},"$id":"com.thrasys.xnet.erp.assets.AssetXeProductConversion.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeProductConversion.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
