/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeInvExpenseCode.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"Code":{"type":"string","maxLength":50},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"EnterpriseID":{"type":"string"},"ExpenseCodeID":{"type":"string","primaryKey":"supplied","maxLength":25},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100}},"required":["Active"],"$id":"com.thrasys.xnet.erp.assets.AssetXeInvExpenseCode.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeInvExpenseCode.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
