/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.assets.AssetXeReportInfo.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeWorkflow.json';
import schema_2 from './com.thrasys.xnet.erp.assets.AssetXeReportSet.json';
import schema_3 from './com.thrasys.xnet.erp.assets.AssetXeUITemplate.json';
import schema_4 from './com.thrasys.xnet.erp.assets.AssetXgServiceType.json';
import schema_5 from './com.thrasys.xnet.erp.assets.AssetXeUISet.json';
import schema_6 from './com.thrasys.xnet.erp.assets.AssetXeVisitType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeServiceSubType.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DefaultReportID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.assets.AssetXeReportInfo.json#"}]},"DefaultWorkflowID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ReportSetID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeReportSet.json#"}]},"ServiceSubTypeID":{"type":"string","primaryKey":"supplied","maxLength":32},"ServiceTemplate":{"oneOf":[{"nullable":true,"type":"string","maxLength":100},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeUITemplate.json#"}]},"ServiceTypeID":{"oneOf":[{"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.erp.assets.AssetXgServiceType.json#"}]},"UISetID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeUISet.json#"}]},"UITemplate":{"oneOf":[{"nullable":true,"type":"string","maxLength":100},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeUITemplate.json#"}]},"VisitTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeVisitType.json#"}]}},"required":["Active"],"$id":"com.thrasys.xnet.erp.assets.AssetXeServiceSubType.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeServiceSubType.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4, schema_5, schema_6]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
