/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeDiagnosisCatalog.json';
import schema_2 from './com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatalog.json';
import schema_3 from './com.thrasys.xnet.erp.assets.AssetXeUISet.json';
import schema_4 from './com.thrasys.xnet.app.assets.AssetXeWorkflow.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeAuthType.json#"},"Active":{"defaultValue":true,"type":"boolean"},"AuthTypeID":{"type":"string","primaryKey":"supplied","maxLength":100},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"DiagnosisCatalogID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeDiagnosisCatalog.json#"}]},"IsInPatient":{"defaultValue":false,"type":"boolean"},"IsRx":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"NeedsConcurReview":{"defaultValue":false,"type":"boolean"},"ParentID":{"oneOf":[{"nullable":true,"type":"string","maxLength":100},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeAuthType.json#"}]},"RestrictValue":{"defaultValue":1,"maximum":99999,"type":"integer"},"Sequence":{"maximum":999,"type":"integer"},"ServiceCodeRefCatalogID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatalog.json#"}]},"TypeBit":{"defaultValue":1,"type":"integer"},"UISetID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeUISet.json#"}]},"WorkflowID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#"}]}},"required":["Active","IsInPatient","IsRx","Name","NeedsConcurReview","RestrictValue"],"$id":"com.thrasys.xnet.erp.assets.AssetXeAuthType.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeAuthType.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
