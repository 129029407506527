/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeMRDArchiveStatus.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeMRDArchiveType.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeFacility.json';
import schema_3 from './com.thrasys.xnet.erp.assets.AssetXeMRDPArchiveStatus.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeMRDArchive.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"ArchiveID":{"type":"integer","primaryKey":"generated"},"ArchiveStatusID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeMRDArchiveStatus.json#"}]},"ArchiveTypeID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeMRDArchiveType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"CurrentSlotNo":{"defaultValue":0,"type":"integer"},"EnterpriseID":{"type":"string"},"FacilityID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeFacility.json#"}]},"HierarchyID":{"type":"integer"},"IsDefault":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"PArchiveStatusID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeMRDPArchiveStatus.json#"}]},"ParentID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeMRDArchive.json#"}]}},"required":["Active","CurrentSlotNo","IsDefault"],"$id":"com.thrasys.xnet.erp.assets.AssetXeMRDArchive.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeMRDArchive.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
