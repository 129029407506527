/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeVisitStatus.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeWorkflow.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeVisitType.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveVisitTimeout":{"maximum":9999999999,"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DefaultOpenStatus":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeVisitStatus.json#"}]},"Description":{"type":"string","maxLength":500},"IsClinicalVisit":{"defaultValue":true,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"RestrictValue":{"defaultValue":1,"maximum":99999,"type":"integer"},"VisitTypeID":{"type":"string","primaryKey":"supplied","maxLength":16},"WorkflowID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#"}]}},"required":["Active","IsClinicalVisit","Name","RestrictValue"],"$id":"com.thrasys.xnet.erp.assets.AssetXeVisitType.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeVisitType.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
