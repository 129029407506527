/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeWaitListCategory.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeWaitListSubCategory.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CategoryBits":{"defaultValue":0,"type":"integer"},"CategoryID":{"oneOf":[{"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeWaitListCategory.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"IsSystem":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ProgramBits":{"defaultValue":1,"type":"integer"},"SubCategoryID":{"type":"string","maxLength":30},"WaitListSubCategoryID":{"type":"integer","primaryKey":"generated"}},"required":["Active","CategoryBits","IsSystem","Name","ProgramBits","SubCategoryID"],"$id":"com.thrasys.xnet.erp.assets.AssetXeWaitListSubCategory.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeWaitListSubCategory.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
