/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeEquipmentType.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeFacility.json';
import schema_3 from './com.thrasys.xnet.app.assets.AssetXeResource.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeLabInstrument.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"EquipmentTypeID":{"oneOf":[{"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.app.assets.AssetXeEquipmentType.json#"}]},"FacilityID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeFacility.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"ResourceID":{"primaryKey":"supplied","oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeResource.json#"}]}},"required":["Active"],"$id":"com.thrasys.xnet.erp.assets.AssetXeLabInstrument.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeLabInstrument.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
