/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeCatContentType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatalog.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CatContentTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeCatContentType.json#"}]},"CatalogID":{"type":"integer","primaryKey":"generated"},"CatalogTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"FilterValue":{"type":"string","maxLength":64},"IsChargeCatalog":{"defaultValue":true,"type":"boolean"},"IsExpSet":{"defaultValue":false,"type":"boolean"},"IsOrderCatalog":{"defaultValue":true,"type":"boolean"},"IsOrderSheetCatalog":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ParentID":{"type":"integer"},"RightID":{"type":"string","maxLength":64},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"required":["Active","IsChargeCatalog","IsExpSet","IsOrderCatalog","IsOrderSheetCatalog"],"$id":"com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatalog.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatalog.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
