/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeClaimBatchStatus.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json#"},"AcceptedTimestamp":{"format":"date-time","nullable":true,"type":"string"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"BatchTimestamp":{"format":"date-time","nullable":true,"type":"string"},"ClaimBatchID":{"type":"string","primaryKey":"generated","maxLength":120},"ClearingHouseID":{"oneOf":[{"nullable":true,"type":"string","maxLength":200},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"ErrorDescription":{"type":"string","maxLength":1024},"IsInError":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"ProcessedTimestamp":{"format":"date-time","nullable":true,"type":"string"},"StatusID":{"oneOf":[{"nullable":true,"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeClaimBatchStatus.json#"}]},"SubmissionTimestamp":{"format":"date-time","nullable":true,"type":"string"}},"required":["Active","IsInError"],"$id":"com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
