/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeAuthType.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXgUmUrgency.json#"},"Active":{"defaultValue":true,"type":"boolean"},"AuthSubTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":100},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeAuthType.json#"}]},"AuthTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":100},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeAuthType.json#"}]},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"IsByAuthType":{"defaultValue":false,"type":"boolean"},"IsOverridable":{"defaultValue":false,"type":"boolean"},"MaxDays":{"maximum":999,"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"Sequence":{"maximum":999,"type":"integer"},"UmUrgencyID":{"type":"string","primaryKey":"supplied","maxLength":32}},"required":["Active","IsByAuthType","IsOverridable","MaxDays","Name"],"$id":"com.thrasys.xnet.erp.assets.AssetXgUmUrgency.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXgUmUrgency.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
