/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeSmartBook.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXgCorrespondenceInfo.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeCustomLanguage.json#"},"Active":{"defaultValue":true,"type":"boolean"},"BookID":{"oneOf":[{"nullable":true,"type":"string","maxLength":64},{"$ref":"com.thrasys.xnet.app.assets.AssetXeSmartBook.json#"}]},"CorrespondenceInfoID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXgCorrespondenceInfo.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"CustomLanguageCode":{"type":"string","maxLength":50},"CustomLanguageID":{"type":"integer","primaryKey":"generated"},"Description":{"type":"string","maxLength":500},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"ExpireDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"ExpireDateTimeOff":{"type":"string"},"InsertMessageID1":{"type":"string","maxLength":200},"InsertMessageID2":{"type":"string","maxLength":200},"InsertMessageID3":{"type":"string","maxLength":200},"InsertMessageID4":{"type":"string","maxLength":200},"IsTranslationReq":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"SequencePosition":{"maximum":99999999,"type":"integer"}},"required":["Active","IsTranslationReq"],"$id":"com.thrasys.xnet.erp.assets.AssetXeCustomLanguage.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeCustomLanguage.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
