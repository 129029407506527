/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatalog.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeServiceCodeCatalog.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CatItemID":{"type":"integer","primaryKey":"generated"},"CatalogID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatalog.json#"}]},"CodeRecordID":{"oneOf":[{"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceCodeCatalog.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"FilterOne":{"type":"string","maxLength":64},"FilterThree":{"type":"string","maxLength":64},"FilterTwo":{"type":"string","maxLength":64},"IsCommon":{"defaultValue":false,"type":"boolean"},"IsMultUnits":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":500},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"required":["Active","IsCommon","IsMultUnits"],"$id":"com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeServiceCodeRefCatDerived.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
