/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeSchedCapacityUom.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeSchedEvent.json';
import schema_2 from './com.thrasys.xnet.erp.assets.AssetXeSchedule.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeSchedCapacityLane.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"BlockedCapacity":{"maximum":99999999,"type":"integer"},"BlockedDays":{"maximum":99999,"type":"integer"},"Capacity":{"maximum":99999999,"type":"integer"},"CapacityUom":{"oneOf":[{"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeSchedCapacityUom.json#"}]},"ColorCode":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":500},"OverbookCapacity":{"maximum":99999999,"type":"integer"},"ReservingEventID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeSchedEvent.json#"}]},"SchedCapacityLaneID":{"type":"integer","primaryKey":"generated"},"ScheduleID":{"primaryKey":"supplied","oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeSchedule.json#"}]}},"required":["Active","Capacity"],"$id":"com.thrasys.xnet.erp.assets.AssetXeSchedCapacityLane.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeSchedCapacityLane.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
