/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXePatientRule.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXePatientRuleSet.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"ObjectID":{"type":"string","maxLength":100},"Operand":{"type":"string","maxLength":40},"Operator":{"type":"string","maxLength":30},"RecordID":{"type":"integer","primaryKey":"generated"},"RuleObject":{"type":"string","maxLength":100},"RuleSetID":{"primaryKey":"supplied","oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatientRule.json#"}]},"SinceHours":{"exclusiveMaximum":1000,"type":"number"}},"required":["Active","ObjectID","Operator","RuleObject"],"$id":"com.thrasys.xnet.erp.assets.AssetXePatientRuleSet.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXePatientRuleSet.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
