/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeLabResultMethod.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeLabTest.json';
import schema_2 from './com.thrasys.xnet.erp.assets.AssetXeOrderBaseLabTest.json';
import schema_3 from './com.thrasys.xnet.app.assets.AssetXeSex.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeOrderBaseLabRange.json#"},"AccountID":{"type":"integer"},"Active":{"type":"boolean"},"AgeHighDays":{"type":"number"},"AgeLowDays":{"type":"number"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"HighConcern":{"type":"number"},"HighNormal":{"type":"number"},"HighPanic":{"type":"number"},"LabResultMethodID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeLabResultMethod.json#"}]},"LabTestID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeLabTest.json#"}]},"LowConcern":{"type":"number"},"LowNormal":{"type":"number"},"LowPanic":{"type":"number"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"OrderLabTestID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeOrderBaseLabTest.json#"}]},"OrderRangeID":{"type":"integer","primaryKey":"generated"},"RangeID":{"type":"integer"},"SexID":{"oneOf":[{"nullable":true,"type":"string"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeSex.json#"}]}},"$id":"com.thrasys.xnet.erp.assets.AssetXeOrderBaseLabRange.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeOrderBaseLabRange.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
