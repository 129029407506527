/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXgDRG.json#"},"Active":{"defaultValue":true,"type":"boolean"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DRGID":{"type":"string","primaryKey":"supplied","maxLength":60},"Description":{"type":"string","maxLength":1200},"EffectiveDate":{"defaultValue":"2009-01-01","format":"date","type":"string"},"ExpireDate":{"defaultValue":"2999-12-31","format":"date","type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":800},"RestrictValue":{"defaultValue":1,"maximum":99999,"type":"integer"}},"required":["Active","Code","EffectiveDate","ExpireDate","RestrictValue"],"$id":"com.thrasys.xnet.erp.assets.AssetXgDRG.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXgDRG.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
