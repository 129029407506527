/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXePatientVisit.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeMRDFolder.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXePatientBirth.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DeliveryComments":{"type":"string","maxLength":300},"EnterpriseID":{"type":"string"},"ErrorDescription":{"type":"string","maxLength":1024},"GestationWeeks":{"maximum":9999,"type":"integer"},"IVID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatientVisit.json#"}]},"IsInError":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"NumChildren":{"maximum":9999,"type":"integer"},"PatientBirthID":{"type":"integer","primaryKey":"generated"},"PatientMrdID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeMRDFolder.json#"}]}},"required":["Active","IsInError"],"$id":"com.thrasys.xnet.erp.assets.AssetXePatientBirth.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXePatientBirth.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
