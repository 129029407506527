/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.assets.AssetXeReportInfo.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeAppWellKnown.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeReportSetItem.json#"},"Active":{"defaultValue":true,"type":"boolean"},"AssetName":{"type":"string","maxLength":300},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DataFilter":{"type":"string","maxLength":1000},"FilterParameters":{"type":"string","maxLength":500},"IsMaster":{"defaultValue":false,"type":"boolean"},"ItemCode":{"type":"string","maxLength":128},"ItemID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":60},"PValues":{"type":"string","maxLength":300},"Parameters":{"type":"string","maxLength":300},"ReportID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.assets.AssetXeReportInfo.json#"}]},"WellKnownID":{"oneOf":[{"nullable":true,"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppWellKnown.json#"}]}},"required":["Active","IsMaster"],"$id":"com.thrasys.xnet.erp.assets.AssetXeReportSetItem.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeReportSetItem.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
