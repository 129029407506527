/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeScanDocType.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXePatient.json';
import schema_2 from './com.thrasys.xnet.erp.assets.AssetXePatientVisit.json';
import schema_3 from './com.thrasys.xnet.erp.assets.AssetXeResultStatusCode.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeScanDoc.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"Comments":{"type":"string","maxLength":1000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DocTypeID":{"oneOf":[{"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeScanDocType.json#"}]},"EnterpriseID":{"type":"string"},"IPID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatient.json#"}]},"IVID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatientVisit.json#"}]},"IsLinked":{"defaultValue":false,"type":"boolean"},"LockDateTime":{"format":"date-time","nullable":true,"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"ScanDocID":{"type":"integer","primaryKey":"generated"},"SourceID":{"type":"string","maxLength":100},"SourceSystem":{"defaultValue":"SYNERGY","type":"string","maxLength":100},"StatusCode":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeResultStatusCode.json#"}]}},"required":["Active","IsLinked","SourceID","SourceSystem"],"$id":"com.thrasys.xnet.erp.assets.AssetXeScanDoc.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeScanDoc.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
