/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeRiskCondition.json#"},"Active":{"defaultValue":true,"type":"boolean"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"ConditionID":{"type":"string","primaryKey":"supplied","maxLength":50},"ConditionThreshold":{"defaultValue":0,"exclusiveMaximum":1000,"type":"number"},"ConditionType":{"defaultValue":"p","type":"string","maxLength":1},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":1000},"ExclusionQuery":{"type":"string","maxLength":2000},"FilterBitID":{"defaultValue":0,"type":"integer"},"FilterQuery":{"type":"string","maxLength":2000},"GeneratedTStamp":{"format":"date-time","nullable":true,"type":"string"},"IsExternal":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ParentConditionID":{"oneOf":[{"nullable":true,"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeRiskCondition.json#"}]},"QueryClassName":{"type":"string","maxLength":200},"RestrictValue":{"defaultValue":1,"maximum":99999,"type":"integer"}},"required":["Active","ConditionType","IsExternal","Name","RestrictValue"],"$id":"com.thrasys.xnet.erp.assets.AssetXeRiskCondition.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeRiskCondition.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
