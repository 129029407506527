/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeSchedCapacityUom.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeResource.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeSchedEvent.json';
import schema_3 from './com.thrasys.xnet.erp.assets.AssetXeServiceLine.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeSchedule.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"CapacityLimit":{"maximum":99999999,"type":"integer"},"CapacityUom":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeSchedCapacityUom.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EndDate":{"format":"date-time","type":"string"},"EndHour":{"maximum":99,"type":"integer"},"EndMinute":{"maximum":99,"type":"integer"},"EnterpriseID":{"type":"string"},"ErrorDescription":{"type":"string","maxLength":500},"HorizonDays":{"maximum":999999,"type":"integer"},"IsInError":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":200},"OverbookLimit":{"maximum":99999999,"type":"integer"},"ResourceID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeResource.json#"}]},"SchedEventID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeSchedEvent.json#"}]},"ScheduleID":{"type":"integer","primaryKey":"generated"},"ServiceLineID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServiceLine.json#"}]},"StartDate":{"format":"date-time","type":"string"},"StartHour":{"maximum":99,"type":"integer"},"StartMinute":{"maximum":99,"type":"integer"}},"required":["Active","EndDate","EndHour","EndMinute","IsInError","StartDate","StartHour","StartMinute"],"$id":"com.thrasys.xnet.erp.assets.AssetXeSchedule.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeSchedule.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
