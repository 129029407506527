/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeClaimBatchStatus.json#"},"Abbr":{"type":"string","maxLength":4},"Active":{"defaultValue":true,"type":"boolean"},"BatchStatusRefID":{"type":"string","primaryKey":"generated","maxLength":32},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DataFilter":{"type":"string","maxLength":100},"Description":{"type":"string","maxLength":2000},"IsActiveStatus":{"defaultValue":true,"type":"boolean"},"IsEditable":{"defaultValue":true,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":200}},"required":["Active","IsActiveStatus","IsEditable"],"$id":"com.thrasys.xnet.erp.assets.AssetXeClaimBatchStatus.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeClaimBatchStatus.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
