/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeSmartBook.json';
import schema_1 from './com.thrasys.xnet.assets.AssetXeReportInfo.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeAppWellKnown.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeSmartBookInstanceReport.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"BookID":{"oneOf":[{"type":"string","maxLength":64},{"$ref":"com.thrasys.xnet.app.assets.AssetXeSmartBook.json#"}]},"ContextID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"InstanceID":{"type":"integer","primaryKey":"generated"},"IsSet":{"defaultValue":false,"type":"boolean"},"IsValid":{"defaultValue":true,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"ParentID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeSmartBookInstanceReport.json#"}]},"ReportID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.assets.AssetXeReportInfo.json#"}]},"ResultValue":{"type":"string","maxLength":4000},"ResultValueID":{"type":"string","maxLength":2000},"Sequence":{"exclusiveMaximum":100000,"type":"number"},"WellKnownID":{"oneOf":[{"nullable":true,"type":"string","maxLength":50},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppWellKnown.json#"}]}},"required":["Active","IsSet","IsValid"],"$id":"com.thrasys.xnet.erp.assets.AssetXeSmartBookInstanceReport.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeSmartBookInstanceReport.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
