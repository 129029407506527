/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeOrderBase.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeFacility.json';
import schema_2 from './com.thrasys.xnet.erp.assets.AssetXeServicePriority.json';
import schema_3 from './com.thrasys.xnet.erp.assets.AssetXePatientVisit.json';
import schema_4 from './com.thrasys.xnet.app.assets.AssetXeUserData.json';
import schema_5 from './com.thrasys.xnet.app.assets.AssetXeWorkflowInstance.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeOrderSession.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"AdmissionOrderID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeOrderBase.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DefaultDateTime":{"format":"date-time","nullable":true,"type":"string"},"DefaultFacilityID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeFacility.json#"}]},"DefaultPriorityID":{"oneOf":[{"nullable":true,"type":"string","maxLength":64},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeServicePriority.json#"}]},"EnterpriseID":{"type":"string"},"IVID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatientVisit.json#"}]},"IsAdmissionPlan":{"defaultValue":false,"type":"boolean"},"IsPlaced":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"SessionID":{"type":"integer","primaryKey":"generated"},"Username":{"oneOf":[{"type":"string","maxLength":60},{"$ref":"com.thrasys.xnet.app.assets.AssetXeUserData.json#"}]},"WorkID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkflowInstance.json#"}]}},"required":["Active","IsAdmissionPlan","IsPlaced"],"$id":"com.thrasys.xnet.erp.assets.AssetXeOrderSession.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeOrderSession.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4, schema_5]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
