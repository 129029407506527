/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeScanDocType.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DocTypeID":{"type":"string","primaryKey":"supplied","maxLength":30},"IsIPIDAllowed":{"defaultValue":false,"type":"boolean"},"IsIPIDRequired":{"defaultValue":false,"type":"boolean"},"IsInternal":{"defaultValue":false,"type":"boolean"},"IsObjectLink":{"defaultValue":false,"type":"boolean"},"IsRootType":{"defaultValue":false,"type":"boolean"},"LinkAssetName":{"type":"string","maxLength":50},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"RestrictValue":{"defaultValue":1,"maximum":99999,"type":"integer"},"Sequence":{"defaultValue":0,"maximum":9999,"type":"integer"}},"required":["Active","IsIPIDAllowed","IsIPIDRequired","IsInternal","IsObjectLink","IsRootType","RestrictValue","Sequence"],"$id":"com.thrasys.xnet.erp.assets.AssetXeScanDocType.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeScanDocType.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
