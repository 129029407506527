/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeResource.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeLabServiceCenter.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeLabCollection.json#"},"AccountID":{"type":"integer"},"CollectedBy":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeResource.json#"}]},"CollectionDateTime":{"format":"date-time","nullable":true,"type":"string"},"CollectionLocation":{"type":"string","maxLength":512},"CollectionMethod":{"type":"string","maxLength":512},"CollectionNotes":{"type":"string","maxLength":1024},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"LabCollectionID":{"type":"integer","primaryKey":"generated"},"LabServiceCenterID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeLabServiceCenter.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"}},"$id":"com.thrasys.xnet.erp.assets.AssetXeLabCollection.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeLabCollection.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
