/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeLabResultMethod.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeLabTest.json';
import schema_2 from './com.thrasys.xnet.app.assets.AssetXeSex.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeLabTestRange.json#"},"Active":{"defaultValue":true,"type":"boolean"},"AgeHighDays":{"defaultValue":54000,"exclusiveMaximum":100000,"type":"number"},"AgeLowDays":{"defaultValue":0,"exclusiveMaximum":100000,"type":"number"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"HighConcern":{"exclusiveMaximum":1000000000,"type":"number"},"HighNormal":{"exclusiveMaximum":1000000000,"type":"number"},"HighPanic":{"exclusiveMaximum":1000000000,"type":"number"},"LabResultMethodID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeLabResultMethod.json#"}]},"LabTestID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeLabTest.json#"}]},"LowConcern":{"exclusiveMaximum":1000000000,"type":"number"},"LowNormal":{"exclusiveMaximum":1000000000,"type":"number"},"LowPanic":{"exclusiveMaximum":1000000000,"type":"number"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"RangeID":{"type":"integer","primaryKey":"generated"},"SexID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.app.assets.AssetXeSex.json#"}]}},"required":["Active"],"$id":"com.thrasys.xnet.erp.assets.AssetXeLabTestRange.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeLabTestRange.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
