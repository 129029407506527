/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeLabTest.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeOrderBase.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeOrderBaseLab.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"IsSampleCreated":{"defaultValue":false,"type":"boolean"},"IsSensitive":{"defaultValue":false,"type":"boolean"},"LabID":{"type":"integer"},"LabTestID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeLabTest.json#"}]},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"OrderID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeOrderBase.json#"}]},"OrderLabID":{"type":"integer","primaryKey":"generated"},"ServiceID":{"type":"integer"}},"required":["Active","IsSampleCreated","IsSensitive"],"$id":"com.thrasys.xnet.erp.assets.AssetXeOrderBaseLab.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeOrderBaseLab.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
