/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.app.assets.AssetXeAppCodeType.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeServiceUnit.json#"},"Abbr":{"type":"string","maxLength":20},"Active":{"defaultValue":true,"type":"boolean"},"Code":{"type":"string","maxLength":30},"CodeTypeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":30},{"$ref":"com.thrasys.xnet.app.assets.AssetXeAppCodeType.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"Sequence":{"defaultValue":1,"exclusiveMaximum":1000,"type":"number"},"UnitCategoryBits":{"defaultValue":0,"type":"integer"},"UnitID":{"type":"string","primaryKey":"supplied","maxLength":64}},"required":["Active","Name","Sequence"],"$id":"com.thrasys.xnet.erp.assets.AssetXeServiceUnit.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeServiceUnit.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
