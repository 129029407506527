/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeHealthPlan.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeHealthPlanPayer.json';
import schema_2 from './com.thrasys.xnet.assets.AssetXeReportInfo.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeStatementReport.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"HealthPlanID":{"oneOf":[{"nullable":true,"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeHealthPlan.json#"}]},"HealthPlanPayerID":{"oneOf":[{"nullable":true,"type":"string","maxLength":32},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeHealthPlanPayer.json#"}]},"IsDefault":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ReportID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.assets.AssetXeReportInfo.json#"}]},"StatementReportID":{"type":"integer","primaryKey":"generated"}},"required":["Active","IsDefault"],"$id":"com.thrasys.xnet.erp.assets.AssetXeStatementReport.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeStatementReport.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
