/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeUISet.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeWorkflow.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeIssueType.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"DaysToComplete":{"maximum":999,"type":"integer"},"Description":{"type":"string","maxLength":500},"IsInPatient":{"defaultValue":false,"type":"boolean"},"IsRx":{"defaultValue":false,"type":"boolean"},"IssueTypeID":{"type":"string","primaryKey":"supplied","maxLength":16},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"Name":{"type":"string","maxLength":100},"ParentID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeIssueType.json#"}]},"TypeBit":{"defaultValue":1,"type":"integer"},"UISetID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeUISet.json#"}]},"WorkflowID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeWorkflow.json#"}]}},"required":["Active","IsInPatient","IsRx","Name"],"$id":"com.thrasys.xnet.erp.assets.AssetXeIssueType.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeIssueType.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
