/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXePatient.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeMergeRequest.json#"},"AccountID":{"type":"integer"},"Comments":{"type":"string","maxLength":2000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"ErrorCode":{"maximum":99999999,"type":"integer"},"ErrorMessage":{"type":"string","maxLength":2000},"MergeID":{"type":"integer","primaryKey":"supplied"},"MergeTStamp":{"format":"date-time","nullable":true,"type":"string"},"MergeType":{"type":"string","maxLength":20},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"SourceHistory":{"type":"integer"},"SourceID":{"type":"string","maxLength":100},"SourcePatient":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatient.json#"}]},"Status":{"type":"string","maxLength":20},"TargetHistory":{"type":"integer"},"TargetID":{"type":"string","maxLength":100},"TargetPatient":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatient.json#"}]}},"required":["MergeType","SourceID","Status","TargetID"],"$id":"com.thrasys.xnet.erp.assets.AssetXeMergeRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeMergeRequest.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
