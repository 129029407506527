/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXeLabStatusCategory.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXeLabStatusCode.json#"},"Active":{"defaultValue":true,"type":"boolean"},"CategoryCodeBits":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeLabStatusCategory.json#"}]},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"Description":{"type":"string","maxLength":500},"DisplayCode":{"type":"string","maxLength":30},"DisplaySequence":{"maximum":99,"type":"integer"},"IsCommentRequired":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"StatusCode":{"type":"string","primaryKey":"supplied","maxLength":30}},"required":["Active","DisplayCode","DisplaySequence","IsCommentRequired"],"$id":"com.thrasys.xnet.erp.assets.AssetXeLabStatusCode.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXeLabStatusCode.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
