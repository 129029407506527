/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXePatient.json';
import schema_1 from './com.thrasys.xnet.app.assets.AssetXeOrgUnit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXePatientMRD.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":"y","type":"string","maxLength":1},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreatedDate":{"format":"date","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EnterpriseID":{"type":"string"},"FileLocation":{"type":"string","maxLength":100},"IPID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatient.json#"}]},"MRDNumber":{"type":"string","maxLength":50},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"OrgUnitID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.app.assets.AssetXeOrgUnit.json#"}]},"PatientMrdID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.assets.AssetXePatientMRD.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXePatientMRD.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
