/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.assets.AssetXePatient.json';
import schema_1 from './com.thrasys.xnet.erp.assets.AssetXeRestrictType.json';
import schema_2 from './com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.assets.AssetXePatientConsent.json#"},"AccountID":{"type":"integer"},"Active":{"defaultValue":true,"type":"boolean"},"ConsentID":{"type":"integer","primaryKey":"generated"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"EndDate":{"format":"date","type":"string"},"EnterpriseID":{"type":"string"},"IPID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXePatient.json#"}]},"IsDenial":{"defaultValue":false,"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string"},"RestrictID":{"oneOf":[{"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeRestrictType.json#"}]},"Signature":{"type":"string","maxLength":50},"SignedDate":{"format":"date","type":"string"},"SourceSystem":{"defaultValue":"SYNERGY","type":"string","maxLength":100},"SourceUser":{"type":"string","maxLength":100},"StartDate":{"format":"date","type":"string"},"VisitAssessmentID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.assets.AssetXeVisitAssessment.json#"}]}},"required":["Active","EndDate","IsDenial","Signature","SignedDate","SourceSystem","StartDate"],"$id":"com.thrasys.xnet.erp.assets.AssetXePatientConsent.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.assets.AssetXePatientConsent.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
